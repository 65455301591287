var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('div',[_c('a-modal',{attrs:{"title":`${_vm.editReportRP ? 'Edit' : 'Add'} Achievement`,"okText":`${_vm.editReportRP ? 'Edit' : 'Create'}`,"visible":_vm.modalVisible,"confirm-loading":_vm.confirmLoading},on:{"ok":function($event){return _vm.handleOkModal(_vm.editReportRP ? 'edit' : 'add')},"cancel":function($event){return _vm.modalInteraction()}}},[_c('a-form-item',{attrs:{"label":"Type"}},[_c('a-input',{staticClass:"mt-3",attrs:{"placeholder":"Insert Achievement's Type"},model:{value:(_vm.newAchievement.type),callback:function ($$v) {_vm.$set(_vm.newAchievement, "type", $$v)},expression:"newAchievement.type"}})],1),_c('a-form-item',{attrs:{"label":"Description"}},[_c('a-textarea',{attrs:{"allow-clear":"","autoSize":{ minRows: 8 }},model:{value:(_vm.newAchievement.description),callback:function ($$v) {_vm.$set(_vm.newAchievement, "description", $$v)},expression:"newAchievement.description"}})],1)],1),_c('a-modal',{attrs:{"visible":_vm.modalEditVisible,"confirm-loading":_vm.confirmLoading,"maskStyle":{
          opacity: 0.5,
          backgroundColor: 'black',
        },"okText":"Update","okButtonProps":{
          props: {
            shape: 'round',
          },
        },"cancelButtonProps":{
          props: {
            shape: 'round',
          },
        }},on:{"ok":_vm.handleOkUpdate,"cancel":_vm.handleCancel}},[_c('h3',{staticStyle:{"color":"#faad14"},attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{staticStyle:{"font-size":"1.2em"},attrs:{"type":"warning"}}),_vm._v(" EDIT Reward & Punishment CONFIRMATION ")],1),_c('p',[_vm._v(" Are you sure you want to update these reward & punishment? The old data for "+_vm._s(_vm.generalReport.murid.nama)+" will be replaced once you update. ")])])],1)],_c('div',{staticClass:"align-items-center"},[_c('div',{staticClass:"d-flex flex-column flex-md-row"},[_c('div',{staticClass:"w-100 w-lg-75"},[_c('a-divider',{attrs:{"orientation":"left"}},[_c('h3',[_vm._v(_vm._s(_vm.generalReport.murid.nama))])]),_vm._m(0)],1),_c('div',{staticClass:"d-flex pt-3"},[_c('a-button',{staticClass:"mb-3",attrs:{"shape":"round","type":"primary","icon":"plus"},on:{"click":function($event){$event.preventDefault();return _vm.modalInteraction('add')}}},[_vm._v("ADD")])],1)]),_c('div',{staticClass:"pt-3"},[_c('a-table',{attrs:{"bordered":"","size":"large","pagination":{ pageSize: 10, hideOnSinglePage: true },"loading":_vm.loadingTable,"columns":_vm.columnsTable,"data-source":_vm.dataTable},scopedSlots:_vm._u([{key:"nullChecker",fn:function(text){return _c('div',{},[(text)?_c('span',[_vm._v(_vm._s(text))]):_c('span',[_vm._v("-")])])}},{key:"action",fn:function(text, record){return _c('div',{},[_c('a-button',{staticClass:"mr-3",attrs:{"size":"default","loading":_vm.loadingDelete,"value":text,"shape":"round","type":"danger","icon":"delete"},on:{"click":function($event){$event.preventDefault();return ((e) => _vm.deleteAchievement(e.target.value, record.key)).apply(null, arguments)}}},[_vm._v("DELETE")]),_c('a-button',{attrs:{"size":"default","loading":_vm.loadingDelete,"value":text,"shape":"round","type":"primary","icon":"form"},on:{"click":function($event){$event.preventDefault();return _vm.modalInteraction('edit', record)}}},[_vm._v("EDIT")])],1)}}])})],1)])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("In this page you can add, edit, delete this student's achievements")])
}]

export { render, staticRenderFns }