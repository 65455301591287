<template>
  <div>
    <template>
      <div>
        <!-- MODAL ADD REWARD / PUNISHMENT -->
        <a-modal
          :title="`${editReportRP ? 'Edit' : 'Add'} Achievement`"
          :okText="`${editReportRP ? 'Edit' : 'Create'}`"
          :visible="modalVisible"
          :confirm-loading="confirmLoading"
          @ok="handleOkModal(editReportRP ? 'edit' : 'add')"
          @cancel="modalInteraction()"
        >
          <a-form-item label="Type">
            <a-input class="mt-3" placeholder="Insert Achievement's Type" v-model="newAchievement.type" />
          </a-form-item>
          <a-form-item label="Description">
            <a-textarea
              v-model="newAchievement.description"
              allow-clear
              :autoSize="{ minRows: 8 }"
            />
          </a-form-item>
        </a-modal>
        <!-- MODAL ADD REWARD / PUNISHMENT -->
        <!-- MODAL EDITING REWARD & PUNISHMENT -->
        <a-modal
          :visible="modalEditVisible"
          :confirm-loading="confirmLoading"
          @ok="handleOkUpdate"
          @cancel="handleCancel"
          :maskStyle="{
            opacity: 0.5,
            backgroundColor: 'black',
          }"
          okText="Update"
          :okButtonProps="{
            props: {
              shape: 'round',
            },
          }"
          :cancelButtonProps="{
            props: {
              shape: 'round',
            },
          }"
        >
          <h3 slot="title" style="color: #faad14">
            <a-icon style="font-size: 1.2em" type="warning" /> EDIT Reward &
            Punishment CONFIRMATION
          </h3>
          <p>
            Are you sure you want to update these reward & punishment? The old data for {{ generalReport.murid.nama }} will
            be replaced once you update.
          </p>
        </a-modal>
        <!-- MODAL EDITING REWARD & PUNISHMENT -->
      </div>
    </template>
    <!-- VIEW -->
    <div class="align-items-center">
      <div class="d-flex flex-column flex-md-row">
        <div class="w-100 w-lg-75">
          <a-divider orientation="left">
            <h3>{{ generalReport.murid.nama }}</h3>
          </a-divider>
          <p v-once>In this page you can add, edit, delete this student's achievements</p>
        </div>
        <div class="d-flex pt-3">
          <a-button
            shape="round"
            type="primary"
            icon="plus"
            class="mb-3"
            @click.prevent="modalInteraction('add')"
            >ADD</a-button>
        </div>
      </div>
      <!-- SHOW TABLE REWARD & PUNISHMENT FOR THIS STUDENT -->
      <div class="pt-3">
        <a-table
          bordered
          size="large"
          :pagination="{ pageSize: 10, hideOnSinglePage: true }"
          :loading="loadingTable"
          :columns="columnsTable"
          :data-source="dataTable"
        >
        <div slot="nullChecker" slot-scope="text">
          <span v-if="text">{{text}}</span>
          <span v-else>-</span>
        </div>
        <div slot="action" slot-scope="text, record">
          <a-button class="mr-3" size="default" :loading="loadingDelete" :value="text" @click.prevent="(e) => deleteAchievement(e.target.value, record.key)" shape="round" type="danger" icon="delete">DELETE</a-button>
          <a-button size="default" :loading="loadingDelete" :value="text" shape="round" type="primary" @click.prevent="modalInteraction('edit', record)" icon="form">EDIT</a-button>
        </div>
        </a-table>
      </div>
    </div>
    <!-- VIEW -->
  </div>
</template>

<script>
// import { message } from 'ant-design-vue'
const columnsTable = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'nullChecker' },
    align: 'center',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'nullChecker' },
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

export default {
  name: 'report-rp',
  data() {
    return {
      loadingCancel: false,
      loadingDelete: false,
      editReportRP: false,
      modalVisible: false,
      modalEditVisible: false,
      confirmLoading: false,
      loadingTable: false,
      columnsTable,
      dataTable: [],
      savedData: [],
      dataMaster: [],
      types: [
        {
          key: 1,
          value: 'Reward',
        },
        {
          key: 2,
          value: 'Punishment',
        },
      ],
      selectType: null,
      filterType: [],
      selectDescription: null,
      selectedScore: null,
      frequency: null,
      note: null,
      newAchievement: {
        type: null,
        description: null,
      },
      idEditAchievement: null,
      idGeneralReport: this.$route.params.id,
    }
  },
  props: {
    generalReport: {
      type: Object,
      required: true,
    },
  },
  methods: {
    modalInteraction(status, data) {
      this.modalVisible = !this.modalVisible
      if (!this.modalVisible) {
        this.newAchievement = {
          type: null,
          description: null,
        }
        this.idEditAchievement = null
        this.editReportRP = false
      }
      if (status === 'add') {
      } else if (status === 'edit') {
        this.editReportRP = true
        this.newAchievement = {
          type: data.type,
          description: data.description,
        }
        this.idEditAchievement = data.key
      }
    },
    async fetchStudentAchievements() {
      this.loadingTable = true
      this.loading = true
      await this.$store
        .dispatch('homeroom/FETCH_ALL_STUDENT_ACHIEVEMENTS', {
          idGeneralReport: this.idGeneralReport,
        })
        .then((data) => {
          console.log(data)
          // console.log('data fetchDataRewardPunishment', data)
          this.dataTable = data.map(row => {
            return {
              key: row.id,
              id: row.id,
              type: row.type,
              description: row.description,
            }
          })
          this.loading = false
          this.loadingTable = false
        })
    },
    showModal() {
      this.modalVisible = true
    },
    handleChange(value, column) {
      if (column === 'Note') {
        this.note = value
      } else if (column === 'Frequency') {
        if (isNaN(Number(value))) {
          setTimeout(() => {
            this.$notification.error({
              message: 'Error',
              description: `This, ${value}'s Frequency must be numbers !!`,
            })
            this.modalConfirm = false
            this.visible = false
          }, 1000)
        } else {
          this.frequency = value
        }
      }
    },
    handleOkModal(status) {
      if (this.newAchievement.type === '' || !this.newAchievement.type) {
        return this.$notification.error({
          message: 'Type of achievement must be filled',
        })
      }
      const homeroomStoreMethod = status === 'add' ? 'CREATE_ACHIEVEMENT_BY_ID_GENERAL_REPORT' : 'UPDATE_ACHIEVEMENT'
      const idGeneralReport = this.$route.params.id
      const params = status === 'add' ? { idGeneralReport } : { id: this.idEditAchievement }
      this.$store.dispatch('homeroom/' + homeroomStoreMethod, { ...params, newAchievement: this.newAchievement })
        .then(_ => {
          const messageStatus = status === 'add' ? 'create' : 'edit'
          this.$notification.success({
            message: `Success ${messageStatus} achievement for ` + this.generalReport.murid.nama,
          })
          this.modalInteraction()
          this.fetchStudentAchievements()
          // this.$emit('fetchDataGeneralReport')
        })
        .catch(err => {
          const messageStatus = status === 'add' ? 'creating' : 'editing'
          console.log(err)
          // this.$emit('fetchDataGeneralReport')
          this.fetchStudentAchievements()
          this.$notification.error({
            message: `error while ${messageStatus} achievement for ` + this.generalReport.murid.nama,
          })
        })
    },
    handleCancel() {
      this.modalVisible = false
      this.modalEditVisible = false
    },
    fetchMasterRP(
      params = {
        page: 'all',
        order: 'ASC',
        sortBy: '',
        search: '',
        type: '',
      },
    ) {
      const idGeneralReport = this.$route.params.id
      this.loading = true
      this.$store
        .dispatch('homeroom/FETCH_ALL_STUDENT_ACHIEVEMENTS', {
          page: params.page,
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          type: params.type,
          idGeneralReport,
        })
        .then((data) => {
          // console.log('data fetchDataRewardPunishment', data)
          this.dataMaster = data.map(row => {
            return {
              id: row.id,
              type: row.type,
              description: row.description,
              score: row.score,
            }
          })
        })
    },
    handleSelectType(value) {
      this.filterType = []
      this.selectedScore = null
      // console.log('value', value)
      this.selectType = value.toLowerCase()
      // console.log('this.selectType', this.selectType)
      const target = this.dataMaster.filter(el => el.type === this.selectType)
      // console.log('target', target)
      if (target) {
        this.filterType = target
        if (this.selectedScore === null && this.selectDescription !== null) {
          this.$notification.warning({
            message: 'DANGER!',
            description:
              `Please re-select the ${this.selectType} description`,
          })
        }
      }
    },
    handleSelectDescription(value) {
      // console.log('value', value)
      this.selectDescription = value
      // console.log('this.dataMaster', this.dataMaster)
      const target = this.dataMaster.filter(el => el.id === this.selectDescription)
      console.log('target', target)
      if (target) {
        this.selectedScore = target[0].score
      }
    },
    toEditReportRP() {
      this.editReportRP = true
    },
    saveEdit() {
      this.modalEditVisible = true
    },
    cancelEdit() {
      this.loadingCancel = true
      setTimeout(() => {
        this.editReportRP = false
        this.loadingCancel = false
      }, 2000)
    },
    handleEditChange(value, key, column) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i])
        if (data[i].frequency === '-') {
          data[i].frequency = null
        }
        if (isNaN(Number(data[i].frequency))) {
          isInvalid = true
          message = `Sorry, ${data[i].description}'s frequency must be numbers. ${i}`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkUpdate() {
      // console.log(this.savedData)
      const newData = [...this.savedData]
      if (newData.length) {
        const validation = this.validateDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.modalVisible = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              id_reward_punishment: row.id_reward_punishment,
              id_general_report: row.id_general_report,
              frequency: Number(row.frequency),
              note: row.note,
            }
          })
          // console.log('Berhasil', target)
          const data = {
            type: 'edit',
            dataEdit: target,
          }
          this.$emit('child-rpp', data)
          this.editReportRP = false
          this.modalEditVisible = false
          this.confirmLoading = false
        }
      } else {
        this.editReportRP = false
        this.confirmLoading = true
        setTimeout(() => {
          this.$notification.error({
            message: 'Sorry',
            description: 'Reward / Punishment has not been updated',
          })
          this.modalEditVisible = false
          this.confirmLoading = false
        }, 1000)
      }
    },
    deleteAchievement(data, key) {
      // this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to delete this { this.generalReport.murid.nama } achievement ? This student data will be lost.</div>
        ),
        onOk: () => {
          // const data = {
          //   type: 'delete',
          //   id: key,
          // }
          this.$store.dispatch('homeroom/DELETE_ACHIEVEMENT', { id: key })
            .then(_ => {
              this.loadingDelete = false
              this.$notification.success({
                message: 'Success delete achievement for ' + this.generalReport.murid.nama,
              })
              this.fetchStudentAchievements()
              // this.$emit('fetchDataGeneralReport')
            })
            .catch(err => {
              this.$notification.error({
                message: 'error while deleting achievement for ' + this.generalReport.murid.nama,
              })
              this.fetchStudentAchievements()
              // this.$emit('fetchDataGeneralReport')
              this.loadingDelete = false
              console.log(err)
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
  },
  mounted() {
    // this.generalReport = this.generalReport
    this.fetchStudentAchievements()
    this.fetchMasterRP()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
.testBorder {
  border: 2px solid black;
}
</style>
